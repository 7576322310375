import { McCCodePreview } from '@maersk-global/community-ui-react-wrapper';
import React, { useState } from 'react';
import Loading from '../components/pages/Loading.jsx';
import TestExecService from '../service/TestExecService.js';

const FailureInfo = ({ testData, onError }) => {
  const testExecService = new TestExecService();

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);
  const [failureInfo, setFailureInfo] = useState(FailureInfoData);

  React.useEffect(() => {
    setApiStatus({ status: Status.InProgress, message: '' });
    testExecService
      .getRecentFailure(testData.testId)
      .then((response) => {
        if (response.status === 200) {
          setFailureInfo(response.data);
        } else if (response.status === 404) {
          // use default
        }
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving failure info: ', error);
        setApiStatus({
          status: Status.Error,
          message: ''
        });
        onError('Something unexpected happened. Please try again by refreshing the page.');
      });
  }, []);

  function formatErrorMessage(errorMsg) {
    const formattedMessage = errorMsg
      .replace(/(\.\s)/g, '.\n')
      .replace(/\\/g, '')
      .replace(/(\t)/g, '  ')
      .replace(/( \\\tat)/g, ' \tat')
      .replace(/(=\s)/g, '= ')
      .replace(/(\s=)/g, ' =')
      .replace(/(\.\n)/g, '.\n\n')
      .replace(/(,)(\s+)/g, ',\n')
      .replace(/\nntat/g, '\n\tat')
      .replace(/(hint="[^"]+")/g, '\n$1');

    return `${formattedMessage}`;
  }

  const renderFailureInfo = () => {
    let actionMessage;

    switch (failureInfo.errorAction) {
      case 'error.system.default':
        actionMessage =
          'Please restart the test. If the error still persists, contact iPACE support.';
        break;
      case 'error.user.test.script':
        actionMessage =
          'Please fix the error in the test script and restart the test. Checks logs if available for details.';
        break;
      default:
        actionMessage = failureInfo.errorAction;
    }

    return (
      <div className="app__page-form">
        <div style={{ marginBottom: '16px' }}>
          <span className="mds-text--medium-bold">What to do:</span> {actionMessage}
        </div>
        <div className="mds-text--x-small-normal">
          <McCCodePreview
            heading="Error Message"
            fit="small"
            variant="none"
            appearance="inverse"
            hidetoggle={true}
            code={[
              {
                language: 'plaintext',
                template: formatErrorMessage(failureInfo.errorMsg)
              }
            ]}></McCCodePreview>
          {/* <pre>{formatErrorMessage(failureInfo.errorMsg)}</pre> */}
        </div>
      </div>
    );
  };

  return (
    <>
      {apiStatus.status === Status.InProgress ? (
        <Loading />
      ) : apiStatus.status === Status.Success ? (
        renderFailureInfo()
      ) : null}
    </>
  );
};

export default FailureInfo;

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error',
  NotSupported: 'NotSupported'
};

const FailureInfoData = {
  errorAction: 'error.system.default',
  errorMsg: 'No error message available. Please checks logs if available.'
};
