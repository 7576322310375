import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import { McTab } from '@maersk-global/mds-react-wrapper/mc-tab';
import { McTabBar } from '@maersk-global/mds-react-wrapper/mc-tab-bar';
import { McStepIndicator } from '@maersk-global/mds-react-wrapper/mc-step-indicator';

import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { McButton, McInput, McOption, McSelect } from '@maersk-global/mds-react-wrapper';
import TestScope from '../chaos/mdsTestScope';
import { getCoreServiceUrl } from '../components/Util.js';
import Loading from '../components/pages/Loading';
import configData from '../config/config.json';
import { ChaosExecutionTabsProvider } from '../hooks/ChaosExecutionTabs';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import ChaosTestExecService from '../service/ChaosTestExecService';
import '../stylesheets/ChaosExecutions.css';
import ChaosDashboard from './ChaosDashboard';
import ChaosTest from './mdsChaosTest';
import ContainerKillChaosTest from './mdsContainerKillChaosTest';
import PodFailureChaosTest from './mdsPodFailureChaosTest';
import PodKillChaosTest from './mdsPodKillChaosTest';
import HttpChaosTest from './httpChaosTest.js';
import HttpReplaceTest from './httpReplaceTest.js';

export default function ConfigureTest() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [activeStepVal, setActiveStepVal] = useState(0);
  const [alertType, setAlertType] = useState('');
  //to control page loads
  const [reLoad, setReLoad] = useState(false);
  //tab status
  const [activeTab, setActiveTab] = useState(0);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [testData, setTestData] = useState();
  const [chaosType, setChaosType] = useState([]);
  const [chaosConfigData, setChaosConfigData] = useState([]);
  const [environment, setEnvironment] = useState('dev');
  const [loading, setLoading] = useState(true);
  //need in TestScope screen
  const [clusterObjMap, setClusterObjMap] = useState(new Map());

  const [disableStopBtn, setDisableStopBtn] = useState(false);

  const { applicationName, isAdminRole, searchApplicationName, userProfile } = UserInfo();
  let chaosMap = new Map();
  let tabSize = 0;

  let dataChaosTypes = configData.chaosType;

  const updateTestData = (testD) => {
    setTestData(testD);
    getDataForPage();
  };
  const location = useLocation();

  const testIdFromlocation =
    location.state !== null && location.state.chaosTestId !== undefined
      ? location.state.chaosTestId
      : '';

  let testId =
    testData !== undefined && testData.testId !== undefined ? testData.testId : testIdFromlocation;

  React.useEffect(() => {
    let promises = [];
    promises.push(getDataForPage());
    promises.push(getConfigDataForChaos(environment));
    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, []);

  function errorDialogOnClose() {
    setShowAlert(false);
  }
  //For chaos config data
  const getConfigDataForChaos = (env) => {
    let chaosTestExecService = new ChaosTestExecService();
    let appNameToSearch = applicationName;
    if (isAdminRole) {
      appNameToSearch = searchApplicationName !== '' ? searchApplicationName : applicationName;
    }
    console.log('appNameToSearch', appNameToSearch);
    if (env !== '') {
      chaosTestExecService
        .getChaosConfigData(getCoreServiceUrl() + '/core-srv/chaos-config' + '/env/' + env)
        .then((response) => {
          let aa = response.data;
          if (aa && aa.length > 0) {
            setChaosConfigData(aa);
            let clusterObjMap1 = chaosTestExecService.getClusterObjectsForEnvV2(aa);
            setClusterObjMap(clusterObjMap1);
          } else {
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage('No data received from the server');
            localStorage.setItem('noClustersForProd', 'true');
          }
        })
        .catch((error) => {
          if (error instanceof Error) {
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage(error.response.data);
          }
        });
    }
  };

  const getDataForPage = () => {
    if (testId !== undefined && testId !== '') {
      let chaosTestExecService = new ChaosTestExecService();
      chaosTestExecService
        .getTestData(getCoreServiceUrl() + '/core-srv/chaos-test-exec/' + testId)
        .then((response) => {
          if (response !== undefined) {
            setTestData(response.data);
            setEnvironment(response.data.environment);
            if (response.data.startDateTime) {
              setStartTime(timeformat(response.data.startDateTime));
            }
            if (response.data.stopDateTime) {
              setEndTime(timeformat(response.data.stopDateTime));
            }

            if (response.data.chaosTestMap !== undefined) {
              chaosMap = new Map(Object.entries(response.data.chaosTestMap));
              //updating the choasTypeMap
              let a = [...chaosType];
              for (const mapKey of chaosMap.keys()) {
                a.push(mapKey);
              }
              //to remove duplicates from the array
              setChaosType([...new Set(a)]);
              tabSize = chaosType.length;
            }
            changeStepperStatus(response.data.status);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const timeformat = (time) => {
    const year = String(time[0]);
    const month = String(time[1]);
    const day = String(time[2]);
    const hours = String(time[3]);
    const minutes = String(time[4]);
    const seconds = String(time[5]);
    const milliseconds = String(time[6]);

    const timeString = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return timeString;
  };
  const handleChaosTypeChange = (event) => {
    let val = [event.detail.value];
    setActiveTab(1);
    setChaosType(val);
  };
  const handleStartTest = async () => {
    let startError = false;
    setLoading(true);
    if (testId !== undefined) {
      let chaosTestExecService = new ChaosTestExecService();
      //validate before start test
      if (testData !== undefined && testData.testId !== undefined) {
        if (chaosType.length === 0) {
          setShowAlert(true);
          setAlertType('error');
          setAlertMessage('Please add a Chaos Experiment to your test.');
          startError = true;
          setActiveTab(0);
        }
      } else {
        setShowAlert(true);
        setAlertType('error');
        setAlertMessage('Please complete configuration of your test before Starting Chaos Test.');
        startError = true;
        setActiveTab(0);
      }
      if (!startError) {
        chaosTestExecService
          .startTest(
            getCoreServiceUrl() +
              '/core-srv/chaos-test-exec/start/' +
              testId +
              '/chaostype/' +
              chaosType[0],
            {
              emailId: userProfile.emailId,
              clusterName: testData.clusterNames[0],
              env: testData.environment
            }
          )
          .then((response) => {
            if (response.status == 200) {
              setTestData(response.data);
              setShowAlert(true);
              setAlertType('info');
              setAlertMessage(
                'Test submitted successfully. Please click on refresh to see the test status change to Started.'
              );
              changeStepperStatus('SUBMITTED');
              setLoading(false);
            } else {
              setLoading(false);
              throw response;
            }
          })
          .catch((error) => {
            if (error instanceof Error) {
              setShowAlert(true);
              setAlertType('error');
              setAlertMessage('Failed to start the test.');
            }
          });
      }
    }
  };
  const handleStopTest = async () => {
    setLoading(true);
    setDisableStopBtn(true);
    if (chaosMap.size == 0 && testData !== undefined) {
      chaosMap = new Map(Object.entries(testData.chaosTestMap));
    }

    let chaosDt = testData !== undefined ? chaosMap.get(chaosType[0]) : '{}';
    if (testId !== undefined && chaosDt !== undefined && chaosDt.status === 'START_TEST') {
      let chaosTestExecService = new ChaosTestExecService();
      chaosTestExecService
        .stopTest(
          getCoreServiceUrl() +
            '/core-srv/chaos-test-exec/stop/' +
            testId +
            '/chaostype/' +
            chaosType[0],
          {}
        )
        .then((response) => {
          if (response.status == 200) {
            setTestData(response.data);
            setShowAlert(true);
            setAlertType('info');
            setAlertMessage('Request to stop test submitted !!.');
            changeStepperStatus('STOPPED');
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error instanceof Error) {
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage('Failed to stop the test.');
            setLoading(false);
            setDisableStopBtn(false);
          }
        });
    }
  };

  const getStepIndicator = () => {
    return (
      <div style={{ marginBottom: '30px', width: '95%' }}>
        <McStepIndicator
          labels={['Submitted', 'Published', 'Started', 'Completed/Stopped']}
          currentindex={activeStepVal}
          fit="small"
          alternativeLabel></McStepIndicator>
      </div>
    );
  };

  const steps = ['Submitted', 'Published', 'Started', 'Completed/Stopped'];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && <div style={{ p: 3 }}>{children}</div>}
      </div>
    );
  }

  const getRefreshData = () => {
    setLoading(true);
    if (testId !== undefined && testId !== '') {
      let chaosTestExecService = new ChaosTestExecService();
      chaosTestExecService
        .getTestData(getCoreServiceUrl() + '/core-srv/chaos-test-exec/' + testId)
        .then((response) => {
          changeStepperStatus(response.data.status);
          updateTestData(response.data);
          setReLoad(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  const changeStepperStatus = (val) => {
    switch (val) {
      case 'DRAFT':
        setActiveStepVal(0);
        break;
      case 'SUBMITTED':
        setActiveStepVal(1);
        break;
      case 'STARTED':
        setActiveStepVal(2);
        break;
      case 'COMPLETED':
        setActiveStepVal(3);
        break;
      case 'STOPPED':
        setActiveStepVal(3);
        break;
      case 'CANCELLED':
        setActiveStepVal(0);
        break;
      default:
        setActiveStepVal(0);
        break;
    }
  };
  const checkForDisableStartButton = () => {
    let testStatusButton = getTestStatus();

    if (testStatusButton === 'DRAFT') {
      return false;
    }
    return true;
  };

  const enableChaosDropDown = () => {
    if (testId === '') {
      return false;
    }
    let testStatusButton = getTestStatus();

    if (testStatusButton === 'DRAFT') {
      return true;
    }
    return false;
  };
  const getTestStatus = () => {
    if (testData === undefined) {
      return 'DRAFT';
    } else {
      return testData.status;
    }
  };

  //handled for only one experiment for the timeBeing. In future this needs to handle for all
  //Only for PODFAILURE it is enabled in ALPHA RELEASE
  const showChaosExpStopButton = (chaosType) => {
    if (chaosMap.size == 0 && testData !== undefined && testData.chaosTestMap !== undefined) {
      chaosMap = new Map(Object.entries(testData.chaosTestMap));
    }

    let chaosDt = chaosMap.get(chaosType);

    if (chaosType == 'PODFAILURE' && chaosDt !== undefined && chaosDt.status == 'START_TEST') {
      return true;
    } else {
      return false;
    }
  };

  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }

    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification icon={icon} body={alertMessage}></McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  tabSize = chaosType.length + 1;

  const shouldAddDashboardTab = () => {
    if (testData === undefined) {
      return false;
    }
    if (testData.status !== 'DRAFT' && testData.status !== 'SUBMITTED') {
      return true;
    }
    return false;
  };

  const renderChaosTest = (chaos, index) => {
    if (chaos === 'PODKILL') {
      return (
        <TabPanel key={chaos} value={activeTab} index={index + 1}>
          <PodKillChaosTest chaosType={chaos} />
        </TabPanel>
      );
    } else if (chaos === 'CONTAINERKILL') {
      return (
        <TabPanel key={chaos} value={activeTab} index={index + 1}>
          <ContainerKillChaosTest chaosType={chaos} />
        </TabPanel>
      );
    } else if (chaos === 'PODFAILURE') {
      return (
        <TabPanel key={chaos} value={activeTab} index={index + 1}>
          <PodFailureChaosTest chaosType={chaos} />
        </TabPanel>
      );
    } else if (chaos === 'HTTPDELAY') {
      return (
        <TabPanel key={chaos} value={activeTab} index={index + 1}>
          <HttpChaosTest chaosType={chaos} />
        </TabPanel>
      );
    } else if (chaos === 'HTTPREPLACE') {
      return (
        <TabPanel key={chaos} value={activeTab} index={index + 1}>
          <HttpReplaceTest chaosType={chaos} />
        </TabPanel>
      );
    } else {
      return (
        <TabPanel key={chaos} value={activeTab} index={index + 1}>
          <ChaosTest chaosType={chaos} />
        </TabPanel>
      );
    }
  };

  let stopButtonLogic = showChaosExpStopButton(chaosType[0]);
  return (
    <>
      {loading && chaosConfigData.length > 0 ? (
        <Loading />
      ) : (
        <div style={{ width: '100%' }}>
          <ChaosExecutionTabsProvider
            testId={testId}
            testData={testData}
            updateTestData={updateTestData}
            chaosConfigData={chaosConfigData}
            setEnvironment={setEnvironment}
            getConfigDataForChaos={getConfigDataForChaos}
            clusterObjMap={clusterObjMap}
            environment={environment}
            addExperiment={reLoad}>
            <div>
              <div style={{ marginBottom: '16px' }}>{getStepIndicator()}</div>

              {showAlert ? alertDia : ''}

              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 3%' }}>
                <div style={{ display: 'flex' }}>
                  <McInput
                    style={{ width: '200px', marginRight: '24px' }}
                    labelposition="left"
                    label="Test ID"
                    disabled={true}
                    placeholder={testId}
                  />
                  <McSelect
                    style={{ marginRight: '24px' }}
                    label="Test Type"
                    disabled={enableChaosDropDown() ? false : true}
                    placeholder="Select Test Type"
                    labelposition="left"
                    value={chaosType[0] ? chaosType[0] : ''}
                    optionselected={(event) => {
                      handleChaosTypeChange(event);
                    }}>
                    {dataChaosTypes.map((type, index) => (
                      <McOption key={index} value={type.value}>
                        {type.name}
                      </McOption>
                    ))}
                  </McSelect>
                  {startTime ? (
                    <>
                      <McInput
                        style={{ marginRight: '24px' }}
                        labelposition="left"
                        label="Start Time"
                        disabled={true}
                        placeholder={startTime}
                      />
                    </>
                  ) : (
                    ''
                  )}
                  {endTime ? (
                    <>
                      <McInput
                        labelposition="left"
                        label="End Time"
                        type="input"
                        disabled={true}
                        placeholder={endTime}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ display: 'flex' }}>
                  <McButton style={{ marginRight: '16px' }} click={getRefreshData}>
                    Refresh
                  </McButton>
                  {getTestStatus() === 'DRAFT' ? (
                    <>
                      <McButton click={handleStartTest} disabled={checkForDisableStartButton()}>
                        Start Test
                      </McButton>
                    </>
                  ) : stopButtonLogic === true ? (
                    <>
                      <McButton click={handleStopTest} disabled={disableStopBtn}>
                        Stop Test
                      </McButton>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="tabs">
                <div style={{ flexGrow: 0.6, marginTop: '-41px' }}>
                  <McTabBar
                    currentindex={activeTab}
                    tabchange={(event) => setActiveTab(event.detail)}>
                    <McTab slot="tab" label="TEST SCOPE" />
                    <div slot="panel">
                      <TabPanel value={activeTab} index={0}>
                        {chaosConfigData.length > 0 ? <TestScope /> : ''}
                      </TabPanel>
                    </div>
                    {chaosType.map((c, index) => (
                      <React.Fragment key={index}>
                        <McTab slot="tab" label={c} />
                        <div slot="panel">{renderChaosTest(c, index)}</div>
                      </React.Fragment>
                    ))}
                    {shouldAddDashboardTab() ? (
                      <React.Fragment>
                        <McTab slot="tab" label="DASHBOARD" />
                        <div slot="panel">
                          <TabPanel value={activeTab} index={tabSize}>
                            <ChaosDashboard />
                          </TabPanel>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </McTabBar>
                </div>
              </div>
            </div>
          </ChaosExecutionTabsProvider>
        </div>
      )}
    </>
  );
}
